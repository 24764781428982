// src/Components/AdminPanel.js

import React, { useState, useEffect } from 'react';
import { db, storage, collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from './firebase'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './AdminPanel.css';

const AdminPanel = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Login state
  const [username, setUsername] = useState(''); // Username state
  const [password, setPassword] = useState(''); // Password state
  const [imageFile, setImageFile] = useState(null);
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [hotDeal, setHotDeal] = useState(false); // Hot Deal state
  const [products, setProducts] = useState([]);
  const [editingId, setEditingId] = useState(null); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [category, setCategory] = useState(''); // New category state

  // Admin credentials
  const ADMIN_USERNAME = 'admin'; // Replace with your admin username
  const ADMIN_PASSWORD = 'password123'; // Replace with your admin password

  // Fetch products from Firestore
  const fetchProducts = async () => {
    setLoading(true); 
    try {
      const productsCollection = collection(db, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Sort products by createdAt in descending order
      productsList.sort((a, b) => {
        const aCreatedAt = a.createdAt ? a.createdAt.toMillis() : 0; // Default to 0 if undefined
        const bCreatedAt = b.createdAt ? b.createdAt.toMillis() : 0; // Default to 0 if undefined
        return bCreatedAt - aCreatedAt; // Sort in descending order (newest first)
      });

      setProducts(productsList);
    } catch (err) {
      console.error("Error fetching products: ", err);
      setError('Error fetching products. Please try again later.');
    } finally {
      setLoading(false); 
    }
  };

  // UseEffect to fetch products on component mount
  useEffect(() => {
    if (isLoggedIn) {
      fetchProducts();
    }
  }, [isLoggedIn]);

  const handleLogin = () => {
    if (username === ADMIN_USERNAME && password === ADMIN_PASSWORD) {
      setIsLoggedIn(true); // Set login status to true
    } else {
      alert('Incorrect username or password. Please try again.');
    }
  };

  const handleAddOrUpdateProduct = async () => {
    if (!imageFile && !editingId) { // Allow updating without changing the image
      alert('Please select an image file to upload.');
      return;
    }

    try {
      let imageUrl;

      // If editing, update the existing product
      if (editingId) {
        if (imageFile) {
          const storageRef = ref(storage, `images/${imageFile.name}`);
          await uploadBytes(storageRef, imageFile);
          imageUrl = await getDownloadURL(storageRef); 
        } else {
          const existingProduct = products.find(product => product.id === editingId);
          imageUrl = existingProduct.image; 
        }

        const productRef = doc(db, 'products', editingId);
        await updateDoc(productRef, { 
          name, 
          link, 
          image: imageUrl, 
          hotDeal, 
          category // Include category
        }); 
      } else {
        const storageRef = ref(storage, `images/${imageFile.name}`);
        await uploadBytes(storageRef, imageFile);
        imageUrl = await getDownloadURL(storageRef);

        const productsCollection = collection(db, 'products');
        // Add new product with createdAt timestamp
        await addDoc(productsCollection, { 
          image: imageUrl, 
          name, 
          link,
          hotDeal, // Add hotDeal field
          category, // Include category
          createdAt: new Date() // Add the creation date
        }); 
      }

      alert('Product saved successfully!');
      setImageFile(null);
      setName('');
      setLink('');
      setHotDeal(false); // Reset hotDeal state
      setCategory(''); // Reset category state
      setEditingId(null); 
      fetchProducts(); 
    } catch (error) {
      console.error("Error saving document: ", error);
      setError('Error saving product. Please try again.'); 
    }
  };

  const handleEditProduct = (product) => {
    setEditingId(product.id);
    setName(product.name);
    setLink(product.link);
    setHotDeal(product.hotDeal || false); // Set hotDeal state
    setCategory(product.category || ''); // Set category state
    setImageFile(null); 
  };

  const handleDeleteProduct = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this product?');
    if (confirmDelete) {
      try {
        const productRef = doc(db, 'products', id);
        await deleteDoc(productRef); 
        alert('Product deleted successfully!');
        fetchProducts(); 
      } catch (error) {
        console.error("Error deleting document: ", error);
        setError('Error deleting product. Please try again.'); 
      }
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="admin-login-container">
        <div className="admin-login-box">
          <h1>Admin Login</h1>
          <div className="login-input">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="login-input">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="login-button">
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-panel">
      <h1 className="admin-panel__title">Admin Panel</h1>
    
      {/* Display error message if any */}
      {error && <p className="admin-panel__error">{error}</p>}
    
      <div className="admin-panel__form">
        <input
          className="admin-panel__input"
          type="text"
          placeholder="Product Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="admin-panel__file-input"
          type="file"
          accept="image/*"
          onChange={(e) => setImageFile(e.target.files[0])}
        />
        <input
          className="admin-panel__input"
          type="text"
          placeholder="Amazon Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        
        {/* New Category Dropdown */}
        <select
          className="admin-panel__input"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          <option value="Men">Men</option>
          <option value="Women">Women</option>
          <option value="Electronics">Electronics</option>
          <option value="Beauty & Fashion">Beauty & Fashion</option>
        </select>

        {/* Hot Deal Checkbox */}
        <label className="admin-panel__checkbox-label">
          <input
            type="checkbox"
            checked={hotDeal}
            onChange={(e) => setHotDeal(e.target.checked)}
          />
          Mark as Hot Deal
        </label>
        
        <button className="admin-panel__button" onClick={handleAddOrUpdateProduct}>
          {editingId ? 'Update Product' : 'Add Product'}
        </button>
      </div>
    
      <h2 className="admin-panel__product-list-title">Product List</h2>
    
      {/* Display loading state */}
      {loading ? (
        <div className="loading-spinner"></div> // Spinner div
      ) : (
        <div className="admin-panel__product-list">
          {products.map(product => (
            <div key={product.id} className="admin-panel__product-item">
              {/* Hot Deal Label */}
              {product.hotDeal && <span className="admin-panel__hot-deal-label">Hot Deal</span>}
              
              <img className="admin-panel__product-image" src={product.image} alt={product.name} />
              <h3 className="admin-panel__product-name">{product.name}</h3>
              <p className="admin-panel__product-category">Category: {product.category}</p> {/* Display category */}
              <div className="admin-panel__product-actions">
                <button className="admin-panel__edit-button" onClick={() => handleEditProduct(product)}>Edit</button>
                <button className="admin-panel__delete-button" onClick={() => handleDeleteProduct(product.id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
