import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Components/Home';
import AdminPanel from './Components/AdminPanel';
import Men from './Components/Men';

import Women from './Components/Women';
import Gadgets from './Components/Gadgets';
import Beauty from './Components/Beauty';




const App = () => {
  return (
    <Router>
      <div>
        <Routes>
         
          <Route path="/" element={<Home/>} />
          <Route path="/men" element={<Men/>} />
          <Route path="/women" element={<Women/>} />
          <Route path="/electronics" element={<Gadgets/>} />
          <Route path="/beauty-fashion" element={<Beauty/>} />
          <Route path="/admin" element={<AdminPanel/>} />
          
        
        </Routes>
       
      </div>
    </Router>
  );
};

export default App;
