// src/Components/Men.js

import React, { useEffect, useState } from 'react';
import { db, collection, getDocs } from './firebase'; // Import Firestore instance and functions
import './Home.css'; // Create this CSS file for Men page styling
import Footer from './Footer';
import Navbar from './Navbar';

const Men = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Sort products by createdAt in descending order, ensuring createdAt exists
      productsList.sort((a, b) => {
        const aCreatedAt = a.createdAt ? a.createdAt.toMillis() : 0; // Default to 0 if undefined
        const bCreatedAt = b.createdAt ? b.createdAt.toMillis() : 0; // Default to 0 if undefined
        return bCreatedAt - aCreatedAt; // Sort in descending order
      });

      setProducts(productsList);
      setLoading(false); // Stop loading after fetching products
    };

    fetchProducts();
  }, []);

  const handleProductClick = (link) => {
    window.open(link, '_blank'); // Open link in new tab
  };

  // Filter products based on category and search query
  const filteredProducts = products.filter(product => 
    product.category === 'Electronics' && product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Navbar/>

      <div className="home">
        <div className="search-container"> {/* Added wrapper div */}
          <input
            type="text"
            placeholder="Search Electronics products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
            className="search-bar"
          />
        </div>
     

        <br />
        <div className="title-container">
          <h3 className="seo-title">Discover Amazing Deals on Electronics Products!</h3>
        </div>

        {/* Display loading state */}
        {loading ? (
          <div className="loading-spinner"></div> // Spinner div
        ) : (
          <div className="product-list">
            {filteredProducts.map(product => (
              <div key={product.id} className="product" onClick={() => handleProductClick(product.link)}>
                {product.hotDeal && (
                  <div className="hot-deal-label">Hot Deal</div>
                )}
                <div className="product-image-container">
                  <img src={product.image} alt={product.name} />
                </div>
                <hr />
                <h2>{product.name}</h2>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Men;
