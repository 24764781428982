// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css"; // Ensure this path is correct based on your project structure

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Top Deals Section */}
        <div className="footer-section about">
          <h2>Top Deals</h2> {/* Changed to Top Deals */}
          <p>Discover amazing offers and exclusive discounts. Don't miss out on the best deals!</p> {/* Updated description */}
        </div>

        {/* Quick Links Section */}
        <div className="footer-section links">
          <h3>Top Brands</h3>
          <ul className="footer-links">
            <li>
            Amazon
            </li>
            <li>
            Flipkart
            </li>
        
            <li>
             Myntra etc..
            </li>
          </ul>
        </div>

        {/* Contact Information Section */}
        <div className="footer-section contact">
          <h3>More</h3>
           <ul className="footer-links">
            <li>
              <Link to="/men">Men</Link>
            </li>
            <li>
            <Link to="/women">Women</Link>
            </li>
            
           
          </ul>
        </div>

        {/* Social Media Section */}
       
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Top Deals. All rights reserved.</p> {/* Updated copyright text */}
      </div>
    </footer>
  );
};

export default Footer;
