import React, { useState } from 'react';
import { FaHome, FaMale, FaFemale, FaMobileAlt, FaSpa } from 'react-icons/fa'; // Import the icons
import './Navbar.css'; // Make sure to import the CSS file

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="admin-navbar">
            <div className="navbar-logo">
                <h2>Telugu Top Deals</h2>
            </div>
            <div className={`navbar-toggle ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
                <span className="toggle-icon">{isOpen ? '✖' : '☰'}</span>
            </div>
            <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
                <li>
                    <a href="/"><FaHome /> Home</a>
                </li>
                <li>
                    <a href="/men"><FaMale /> Men</a>
                </li>
                <li>
                    <a href="/women"><FaFemale /> Women</a>
                </li>
                <li>
                    <a href="/electronics"><FaMobileAlt /> Electronics</a>
                </li>
                <li>
                    <a href="/beauty-fashion"><FaSpa /> Beauty & Fashion</a>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
