// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, getDocs, addDoc, updateDoc,deleteDoc } from 'firebase/firestore'; // Import additional Firestore functions
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC9Dv_S2lHs7iFUR9_EdmnwvNisssHAA54",
    authDomain: "matrimony-350302.firebaseapp.com",
    projectId: "matrimony-350302",
    storageBucket: "matrimony-350302.appspot.com",
    messagingSenderId: "654246249732",
    appId: "1:654246249732:web:f65777d2e0dcce67f5fc51",
    measurementId: "G-EJ8NMSEFFY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage, collection, doc, getDocs, addDoc, updateDoc, deleteDoc}; // Export added functions
