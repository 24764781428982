// src/Components/Home.js

import React, { useEffect, useState } from 'react';
import { db, collection, getDocs } from './firebase'; // Import Firestore instance and functions
import './Home.css';
import './TelegramPopup.css'; // Import CSS for the popup
import Footer from './Footer';
import Navbar from './Navbar';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20; // Number of products to show per page

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      productsList.sort((a, b) => {
        const aCreatedAt = a.createdAt ? a.createdAt.toMillis() : 0;
        const bCreatedAt = b.createdAt ? b.createdAt.toMillis() : 0;
        return bCreatedAt - aCreatedAt;
      });

      setProducts(productsList);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  const handleProductClick = (link) => {
    window.open(link, '_blank');
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate current products to display
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // Total pages calculation
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Generate page numbers
  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        paginationNumbers.push(
          <button
            key={i}
            onClick={() => paginate(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </button>
        );
      } else if (paginationNumbers[paginationNumbers.length - 1] !== '...') {
        paginationNumbers.push(<span key={i}>...</span>);
      }
    }
    return paginationNumbers;
  };

  return (
    <div>
      <Navbar />
      <div className="home">
        <div className="search-container">
          <input
            type="text"
            placeholder=" 🔍 Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-bar"
          />
        </div>

        <br />
        <div className="title-container">
          <h3 className="seo-title">Discover Amazing Deals and Offers!</h3>
        </div>
        
        {loading ? (
          <div className="loading-spinner"></div>
        ) : (
          <div className="product-list">
            {currentProducts.map(product => (
              <div key={product.id} className="product" onClick={() => handleProductClick(product.link)}>
                {product.hotDeal && (
                  <div className="hot-deal-label">Hot Deal</div>
                )}
                <div className="product-image-container">
                  <img src={product.image} alt={product.name} />
                </div>
                <hr />
                <h2>{product.name}</h2>
              </div>
            ))}
          </div>
        )}

        {/* Pagination Controls */}
        <div className="pagination">
          {renderPaginationNumbers()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
